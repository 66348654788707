import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Dropbox, files } from "dropbox";
import axios from 'axios' // Import the necessary type definition for 'files'
import Navbar from "./Navbar";
// import bcrypt from "bcrypt";
// import jwt from "jsonwebtoken";

const Ads = () => {
  const [token, setToken] = useState("");
  const [images, setImages] = useState<{ filename: string; data: string }[]>([]);
  const [ad, setAd] = useState<any>({});
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [verified, setVerified] = useState(false);

  async function CheckIfUserVerified() {
    const r = await axios.post("https://homestaykashmir.com:6969/api/check-if-user-verified", {
      token: localStorage.getItem("token"),
    });
    if (r.data.status !== 200) {
      alert("wait for your account to get verified before booking ad")
      window.location.href = "/"
      setVerified(false)
      return
    }
    if (r.data.status === 200) {
      setVerified(true)
    }
  }
  React.useEffect(() => {
    adDetailsById();
    CheckIfUserVerified();
  }, []);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const minDate = tomorrow.toISOString().split("T")[0];

  async function adDetailsById() {
    const data = await fetch("https://homestaykashmir.com:6969/api/ad-details-by-id", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    const response = await data.json();
    console.log(response);
    setAd(response.ad[0]);
    // setToken(response.token);
    // setMail(response.ad[0].posted_user_email);
    // const accessToken =
    //   "sl.B_BOkunzxRu8pNiKnejg4ts1yjJ_7TB5Ud2W6bOUAUVI6GDHwrhzlE5eP6lOtJwqYe3mG-45An71sUuWjo5OTn1CDb-LYPSDRa-RoNgd6n_EYdQF65E5HpZofU6di-pfHITIQtt0LeRssUGDAjQhht4"; // Replace with your Dropbox access token
    // const folderPath = `/${response.token}/${id}/`; // Specify your folder path (e.g., '/folder-name')

    // Initialize Dropbox client with access token
    // const dbx = new Dropbox({ accessToken });

    try {
      //get images from https://homestaykashmir.com:6969/api/images with token and ad_id
      const r = await axios.post("https://homestaykashmir.com:6969/api/images", {
        token: response.token,
        ad_id: id
      })
      setLoading(true);
      // console.log(r.data)
      setImages(r.data.images)
      // if (!r.data.success) {
      //   setImages([])
      // }
      console.log(images)
      setLoading(false)
    } catch (error) {
      console.error("Error fetching files from Dropbox:", error);
    } finally {
      setLoading(false);
    }
  }
  // Update the type references to use 'files.' prefix

  const [loading, setLoading] = useState(true);

  // RAZORPAY PAYMENT GATEWAY

  function loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      document.body.appendChild(script);
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
    });
  }

  async function displayRazorpay(e: any) {

    // check if user is verified

    e.preventDefault();

    if (!verified) {
      alert("wait for your account to get verified before booking ad")
      return
    }


    // console.log(from, to)
    if (from === "" || to === "") {
      setErrorMessage("Please select from and to date")
      return
    }

    // create a new order
    const data = await fetch("https://homestaykashmir.com:6969/api/create-order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ amount: ad.price_per_room }),
    });
    const response = await data.json();
    console.log(response.response.id);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load, are u online?");
      return;
    }

    var options = {

      key: "rzp_test_ycXpmqnI1Zr8rU",
      amount: ad.price_per_room * 100,
      currency: "INR",
      name: "Room Booking",
      description: "Room Booking",
      order_id: response.response.id,
      handler: async function (response: any) {
        const book = await axios.post("https://homestaykashmir.com:6969/api/booking", {
          ad_id: id,
          booked_by: localStorage.getItem("token"),
          posted_by: ad.posted_user_email,
          booking_from: new Date(from).getTime(),
          booking_to: new Date(to).getTime(),
        })

        const sendOtp = await axios.post("https://homestaykashmir.com:6969/api/send-booking-otp", {
          token: localStorage.getItem("token"),
        })
      },
    };

    var paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
  }

  const { id } = useParams();
  return (
    <div>
      <Navbar />
      {
        !verified ? <h1>wait for your account to get verified before booking ad</h1> : <>
          <div>
            {/*  show ad details */}
            <div
              key={ad.id}
              style={{
                border: "1px solid black",
                padding: "10px",
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              <h1>Title : {ad.title}</h1>

              <p>Description : {ad.description}</p>
              <p>City : {ad.house_city}</p>
              <p>State : {ad.house_state}</p>
              <p>Price per room : {ad.price_per_room}</p>
              <p>Total rooms : {ad.total_rooms}</p>
              {/* room cooling available if 1 else not available */}
              <p>
                Room cooling :{" "}
                {ad.room_cooling === 1 ? "Available" : "Not Available"}
              </p>
              {/* furnished if 1 else not furnished */}
              <p>Furnished : {ad.furnished === 1 ? "Yes" : "No"}</p>
              {/* hot and cold water available if 1 else not available */}
              <p>
                Hot and cold water :{" "}
                {ad.hot_cold_water === 1 ? "Available" : "Not Available"}
              </p>
              {/* food available if 1 else not available */}
              <p>Food : {ad.food === 1 ? "Available" : "Not Available"}</p>
              {/* taxi service available if 1 else not available */}
              <p>
                Taxi service :{" "}
                {ad.taxi_service === 1 ? "Available" : "Not Available"}
              </p>
              {/* doctor on call available if 1 else not available */}
              <p>
                Doctor on call :{" "}
                {ad.doctor_on_call === 1 ? "Available" : "Not Available"}
              </p>
              {/* ad is active if 1 else not active */}
              <p>Active : {ad.is_active === 1 ? "Yes" : "No"}</p>

              {/* book from and to input fields */}
              <div>
                <label htmlFor="from" className="mr-10">From</label>
                <input type="date" id="from" name="from" min={new Date().toISOString().split("T")[0]} value={from} onChange={(e) => setFrom(e.target.value)} />

                <label htmlFor="to" className="mx-10">To</label>
                <input type="date" id="to" name="to"
                  value={to} onChange={(e) => setTo(e.target.value)} min={
                    from === "" ? new Date().toISOString().split("T")[0] : from
                  }
                //  min={minDate}
                />
              </div>

              {loading ? (
                <p>Loading files...</p>
              ) : images.length > 0 ? (
                <div>
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={image.data}
                      alt={`Image ${index + 1}`}
                      style={{ width: "200px", margin: "10px" }}
                    />
                  ))}
                </div>
              ) : (
                <p>No files found in the specified ad.</p>
              )}

              {/* Book Now Button */}
              <div>
                <button
                  // disabled if from or to is empty
                  disabled={(from === "" || to === "") && !verified}
                  onClick={displayRazorpay}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    padding: "10px",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  Book Now
                </button>
                <p className="text-red-500">{errorMessage}</p>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default Ads;
