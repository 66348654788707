import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

const EditProfile = () => {
  const [fname, setFname] = React.useState("");
  const [lname, setLname] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");

  const navigate = useNavigate();
  let token = localStorage.getItem("token");

  React.useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    checkToken();
  }, []);
  async function checkToken() {
    const check = await axios.post("https://homestaykashmir.com:6969/api/verify-token", {
      token,
    });

    if (check.data.status != 200) {
      navigate("/login");
      return;
    } else {
      const getData = await axios.post(
        "https://homestaykashmir.com:6969/api/get-details-by-token",
        { token }
      );
      console.log(getData.data.phone);
      setFname(getData.data.first_name);
      setLname(getData.data.last_name);
      setPhone(getData.data.phone);
      setEmail(getData.data.email);
    }
  }

  async function handleChanges(e: any) {
    e.preventDefault();
    const data = await axios.post("https://homestaykashmir.com:6969/api/edit-profile", {
      first_name: fname,
      last_name: lname,
      email,
      phone
    });
    if (data.data.status === 200) {
      navigate("/");
    }
  }
  return (
    <>
      <Navbar />
      {/* form to edit profile, email is not editable, first name , last name and phone number are changable */}

      <div>
        <form className="flex flex-col items-center">
          <h1>Edit Profile</h1>
          <div>
            <label htmlFor="fname">First Name</label>
            <input
              type="text"
              className="border border-black ml-10 p-2 mt-10"
              value={fname}
              onChange={(e) => setFname(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="lname">Last Name</label>
            <input
              type="text"
              className="border border-black ml-10 p-2 mt-10"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              className="border border-black ml-10 p-2 mt-10"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="text"
              className="border w-96 border-black ml-10 p-2 mt-10"
              value={email}
            />
          </div>

          <input
            onClick={handleChanges}
            type="submit"
            value="Save Changes"
            className="bg-black w-32 mt-10 p-4 text-white"
          />
        </form>
      </div>
    </>
  );
};

export default EditProfile;
