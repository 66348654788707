import React from 'react';
import Hero from '../components/Hero';
import RoomFilter from '../components/RoomFilter';
import FeaturedHomestays from '../components/FeaturedHomestays';
import HowItWorks from '../components/HowItWorks';
import ReviewCarousel from '../components/ReviewCarousel';
import CallToAction from '../components/CallToAction';

const Home = () => {
  return (
    <>
      <Hero />
      <div className="container mx-auto px-4">
        <RoomFilter />
      </div>
      <FeaturedHomestays />
      <HowItWorks />
      <ReviewCarousel />
      <CallToAction />
    </>
  );
};

export default Home;