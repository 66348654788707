import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import LogoutButton from './components/LogoutButton';
import Home from "./pages/Home";
import ForgotPassword from "./pages/ForgotPassword";
import CreateAd from "./pages/CreateAd";
import EditProfile from "./pages/EditProfile";
import Ads from "./pages/Ads";
import Admin from "pages/Admin";
import Profile from "./pages/Profile";
import MyBookings from "pages/MyBookings";
import MyAds from "pages/MyAds";
import MyTransactions from "pages/MyTransactions";
import AdminLogin from "pages/AdminLogin";
import Requests from "pages/Requests";
import AdminUserDetails from "pages/AdminUserDetails";
import AdminVerifyOtp from "pages/AdminVerifyOtp";
import Footer from './components/Footer'; // Adjust the path to your Footer component
import Header from './components/Header'; // Adjust the path to your Header component
import Rooms from './pages/Rooms';
import About from './pages/About';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import PaymentPage from './pages/Payment'; // Assuming this component exists
const App: React.FC = () => {
  return (
    
    <BrowserRouter>
      <div className="min-h-screen flex flex-col">
        <Header />
        {/* Your Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/logout" element={<LogoutButton />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/create-ad" element={<CreateAd />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/ads/:id" element={<Ads />} />
          <Route path="/admin-dashboard" element={<Admin />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/my-bookings" element={<MyBookings />} />
          <Route path="/my-ads" element={<MyAds />} />
          <Route path="/my-transactions" element={<MyTransactions />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/my-ads/requests/:id" element={<Requests />} />
          <Route path="/admin/user-details/:email" element={<AdminUserDetails />} />
          <Route path="/admin/validate-otp" element={<AdminVerifyOtp />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/payment" element={<PaymentPage />} />
        </Routes>

        {/* Footer is added below the Routes */}
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
