import React from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const AdminUserDetails = () => {
    const { email } = useParams();
    const [loading, setLoading] = React.useState(false)
    const [images, setImages] = React.useState<{ filename: string; data: string }[]>([]);
    const [user, setUser] = React.useState<any>({})

    async function getImagesByEmail() {
        setLoading(true)
        const response = await axios.post("https://homestaykashmir.com:6969/api/get-images-by-email", {
            email,
            token: localStorage.getItem("admin-token")
        })
        console.log(response.data)
        // images are in these format [{filename: "filename", data: "base64 encoded image"}]
        setImages(response.data.images)
        setUser(response.data.user)
        setLoading(false)
    }

    React.useEffect(() => {
        getImagesByEmail()
    }, [])

    async function approveUser(email: any) {
        setLoading(true)
        const response = await axios.post("https://homestaykashmir.com:6969/api/approve-user", {
            email,
            token: localStorage.getItem("admin-token")
        })
        console.log(response.data)
        if (response.data.status === "200") {
            alert("User approved successfully")
            // navigate to admin dashboard
            window.location.href = "/admin"
        }
        setLoading(false)

    }
    async function rejectUser(email: any) {
        setLoading(true)
        const response = await axios.post("https://homestaykashmir.com:6969/api/reject-user", {
            email,
            token: localStorage.getItem("admin-token")
        })
        // console.log(response.data)
        if (response.data.status === "200") {
            alert("User rejected successfully")
            // navigate to admin dashboard
            window.location.href = "/admin"
        }
    }

    return (
        <div>
            {
                !user.email ? <>User Already Verified</> :
                    <>
                        {
                            user.isVerified ? <h1>User is already verified</h1> :
                                <div>

                                    {
                                        loading ? <div>Loading</div> : <div>
                                            <h3>Name : {user.first_name} {user.last_name}</h3>
                                            <p>Email : {user.email}</p>
                                            <p>{
                                                user.phone ? `Phone : ${user.phone}` : "Phone : Not provided"
                                            }</p>
                                            {
                                                images.map((image: any) => {
                                                    return (
                                                        <img src={image.data} alt={image.filename} height={250} width={250} />
                                                    )
                                                })
                                            }
                                            <button onClick={() => approveUser(email)} className='m-5'>Approve</button>
                                            <button onClick={() => rejectUser(email)} > Reject</button>
                                        </div>
                                    }
                                </div>
                        }
                    </>
            }

        </div >
    )
}

export default AdminUserDetails