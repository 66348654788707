import React from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Admin = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  // const [users, setUsers] = React.useState([])
  const [users, setUsers] = React.useState<any>([])
  // verify admin-token 
  let adminToken = localStorage.getItem("admin-token")
  async function verifyAdminToken() {
    setLoading(true)
    const response = await axios.post("https://homestaykashmir.com:6969/api/verify-admin-token", {
      token: adminToken
    })
    if (response.data.status !== 200) {
      alert("You are not authorized to access this page")
      window.location.href = "/admin-login"
    }
    else {
      // console.log("Admin token verified")
      setLoading(false)
    }
  }

  async function NavigteToValiateOtp() {
    navigate('/admin/validate-otp')
  }


  async function getUnverifiedUsers() {
    setLoading(true)
    const response = await axios.post("https://homestaykashmir.com:6969/api/get-unverified-users", {
      token: adminToken
    })
    setUsers(response.data.data)
    setLoading(false)
    // console.log(response.data)
  }

  // get images of unVerified users from /api/get-images-by-email route and pair them with email of ysers array



  React.useEffect(() => {
    verifyAdminToken()
    getUnverifiedUsers()
  }, [])

  return (
    <div>
      {
        loading ? <div>Loading</div> : <div>

          {
            users.length <= 0 ? <div> <button className='block border p-4' onClick={() => NavigteToValiateOtp()}>Validate OTP </button> No unverified users</div> :

              // user data contains email, first_name, last_name, phone, id , images
              users.map((user: any) => {
                return (
                  <div key={user.id} className="border p-10">
                    <h3>{user.first_name} {user.last_name}</h3>
                    <p>{user.email}</p>
                    <p>{user.phone}</p>
                    {
                      user.images ? user.images.map((image: any) => {
                        return <img key={image.id} src={image.image_url} alt="user" />
                      }) : <button className='mt-5' onClick={() => navigate(`/admin/user-details/${user.email}`)}>Show Details</button>
                    }
                  </div>
                )
              })


          }
        </div>
      }
    </div>
  )
}

export default Admin