import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const Register = () => {
  const [fname, setFname] = React.useState("");
  const [lname, setLname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  // get aadhar and pan image
  const [files, setFiles] = React.useState<File[]>([]);

  const navigate = useNavigate();

  const handleFileChange = (e: any) => {
    // const selectedFiles: any = Array.from(e.target.files);
    setFiles(e.target.files);
  };

  const handleLoginSuccess = async (response: any) => {
    // Decode the credential to get user profile information
    const token = response.credential;
    const userObject = JSON.parse(atob(token.split('.')[1]));
    const { email, name } = userObject; // Retrieve email and name

    // console.log("User email:", email);
    // console.log("User name:", name);

    const data = await axios.post("https://homestaykashmir.com:6969/api/google-register", {
      email,
      first_name: name.split(" ")[0],
      last_name: name.split(" ")[1],
    });

    if (data.data.status === 200) {
      navigate("/login")
    }


  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {


    e.preventDefault();
    const data = await axios.post("https://homestaykashmir.com:6969/api/register/email", {
      email,
      password,
      first_name: fname, last_name: lname
    });

    if (data.data.status === 200) {
      const formData = new FormData();
      formData.append("email", email);
      Array.from(files).forEach((file) => {
        formData.append("docs", file);
      });
      try {
        const response = await axios.post("https://homestaykashmir.com:6969/api/store-aadhar-pan-images", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response.data);

      } catch (e) {
        console.log(e)
      }
      navigate("/login");
    }
    if (data.data.status === 400) {
      alert("Email already exists")
      return
    }
  };
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Register for account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            action="#"
            method="POST"
            className="space-y-6"
            onSubmit={handleSubmit}
          >
            <div>
              <label
                htmlFor="fname"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                First Name
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setFname(e.target.value)}
                  value={fname}
                  id="fname"
                  name="fname"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="lname"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Last Name
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setLname(e.target.value)}
                  value={lname}
                  id="lname"
                  name="lname"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="docs"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Upload Aadhar and Pan Images
              </label>
              <div className="mt-2">
                <input
                  onChange={handleFileChange}
                  id="docs"
                  name="docs"
                  type="file"
                  // only png/jpg/jpeg accepted
                  accept="image/png, image/jpeg, image/jpg"
                  required
                  multiple
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Register Now
              </button>
            </div>
            <GoogleOAuthProvider clientId="446750503828-uj0m2t1ubnqnsd48483sv1brb624oegj.apps.googleusercontent.com">

              <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={() => console.log("Error")}
              />
            </GoogleOAuthProvider>

            {/* upload aadhar and pan images */}

            <div className="text-sm text-center">
              <p>
                Already have an account?{" "}
                <a
                  href="/login"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Sign in
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
