import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';

const Contact: React.FC = () => {
  return (
    <>
      <style>
        {`
          .contact-container {
            max-width: 1000px;
            margin: 50px auto;
            padding: 20px;
            background: #ffffff;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            animation: fadeIn 1.5s ease-in-out;
          }
          .contact-title {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 30px;
            text-align: center;
          }
          .contact-grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;
          }
          @media (min-width: 768px) {
            .contact-grid {
              grid-template-columns: 1fr 1fr;
            }
          }
          .contact-form .form-group {
            margin-bottom: 15px;
          }
          .contact-form label {
            display: block;
            margin-bottom: 5px;
            font-weight: 600;
          }
          .contact-form .form-input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 1rem;
          }
          .contact-form .form-button {
            background: #4f46e5;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1rem;
            transition: background 0.3s ease;
          }
          .contact-form .form-button:hover {
            background: #4338ca;
          }
          .contact-info .info-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            animation: slideUp 1s ease-out;
          }
          .contact-info .info-icon {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            color: #4f46e5;
          }
          .map {
            margin-top: 20px;
            height: 200px;
            border-radius: 5px;
            overflow: hidden;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            animation: fadeIn 1.5s ease-in-out;
          }
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes slideUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
      <div className="contact-container">
        <h1 className="contact-title">Contact Us</h1>
        <div className="contact-grid">
          <div className="contact-form">
            <form>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" className="form-input" />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" className="form-input" />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" rows={4} className="form-input"></textarea>
              </div>
              <button type="submit" className="form-button">
                Send Message
              </button>
            </form>
          </div>
          <div className="contact-info">
            <div className="info-item">
              <Mail className="info-icon" />
              <div>
                <h3>Email</h3>
                <p>contact@homestaykashmir.com</p>
              </div>
            </div>
            <div className="info-item">
              <Phone className="info-icon" />
              <div>
                <h3>Phone</h3>
                <p>+91 123 456 7890</p>
              </div>
            </div>
            <div className="info-item">
              <MapPin className="info-icon" />
              <div>
                <h3>Address</h3>
                <p>Srinagar, Kashmir - 190001</p>
              </div>
            </div>
          </div>
        </div>
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26372.027385856!2d74.79!3d34.08!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzTCsDA0JzQ4LjAiTiA3NMKwNDcnMjQuMCJF!5e0!3m2!1sen!2sin!4v1635835626789!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Contact;
