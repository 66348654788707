import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  async function goToHome() {
    navigate("/");
  }

  async function goToCreateAd() {
    navigate("/create-ad");
  }

  async function goToProfile() {
    navigate("/profile");
  }

  async function logout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  async function goToMyTransactions() {
    navigate("/my-transactions");
  }

  return (
    <div className="bg-[#FFBC59] p-4 shadow-xl container mx-auto">
      <div className="flex justify-between items-center">
        {/* Left side with logo */}
        <div className="flex items-center space-x-4">
          <img src="path_to_your_logo.png" alt="Logo" className="w-12 h-12" />
        </div>

        {/* Right side with navigation links */}
        <div className="flex space-x-8">
          <div
            className="text-black cursor-pointer hover:bg-white hover:text-black px-4 py-2 rounded-2xl transition-all duration-300"
            onClick={goToHome}
          >
            Home
          </div>
          <div
            className="text-black cursor-pointer hover:bg-white hover:text-black px-4 py-2 rounded-2xl transition-all duration-300"
            onClick={goToCreateAd}
          >
            Create Ad
          </div>
          <div
            className="text-black cursor-pointer hover:bg-white hover:text-black px-4 py-2 rounded-2xl transition-all duration-300"
            onClick={goToProfile}
          >
            Profile
          </div>
          <div
            className="text-black cursor-pointer hover:bg-yellow-400 hover:text-black px-4 py-2 rounded-2xl transition-all duration-300"
            onClick={goToMyTransactions}
          >
            My Transactions
          </div>
          <div
            className="text-black cursor-pointer hover:bg-yellow-400 hover:text-black px-4 py-2 rounded-2xl transition-all duration-300"
            onClick={logout}
          >
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
