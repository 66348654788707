import React from 'react'
import Navbar from '../components/Header'
import axios from 'axios'

const MyTransactions = () => {
    const [transactions, setTransactions] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [rating, setRating] = React.useState(0)
    async function getTransactions() {
        setLoading(true)
        const data = await axios.post('https://homestaykashmir.com:6969/api/transactions-by-token', {
            token: localStorage.getItem("token")
        })
        if (data.data.status === 404) {
            alert("No Bookings Found")
            // redirect to home page
            window.location.href = "/"
        }
        setTransactions(data.data.transactions)
        setLoading(false)
    }

    async function submitRating(ad_id: any, rating: any, token: any) {
        const data = await axios.post('https://homestaykashmir.com:6969/api/rating', {
            token: localStorage.getItem("token"),
            ad_id,
            rating
        })
        // console.log(data.data)
    }

    React.useEffect(() => {
        getTransactions()
    }, [])
    return (
        <div>

            <Navbar />
            {
                loading ? <h1>Loading...</h1> :
                    <div>
                        <h1>My Transactions</h1>

                        <div
                            style={{
                                border: "1px solid black",
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                            }}
                        >
                            {transactions.map((transaction: any) => (
                                <div key={transaction.transaction.booking_time} className='border p-10'>
                                    <h1 className='font-bold'>Ad Title : {transaction.ad.title}</h1>
                                    <p>Amount : {transaction.ad.price_per_room}</p>
                                    <p
                                        className={
                                            transaction.transaction.approved === 1 ? "text-green-500" : "text-red-500"
                                        }
                                    >Approved : {
                                            transaction.transaction.approved === 1 ? "Yes" : "No"
                                        }
                                    </p>

                                    {
                                        // if approved, take rating input from user in stars
                                        transaction.transaction.approved === 1 &&
                                        <div className='flex flex-col'>
                                            <select name="" id="" value={rating
                                            } onChange={(e) => setRating(parseInt(e.target.value))
                                            }>
                                                <option value="">Select Rating</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                            <button disabled={
                                                rating === 0

                                            } className='bg-black text-white rounded mt-5' onClick={() => {
                                                submitRating(transaction.transaction.ad_id, rating, localStorage.getItem("token"))
                                            }}>Submit</button>
                                        </div>

                                    }
                                </div>
                            ))}
                        </div>
                    </div>
            }


        </div>
    )
}

export default MyTransactions