import axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom'

const Requests = () => {

    const { id } = useParams<{ id: string }>()
    const [requests, setRequests] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    async function getRequests() {
        setLoading(true)
        try {
            const response = await axios.post("https://homestaykashmir.com:6969/api/get-approve-requests", {
                token: localStorage.getItem("token"),
                ad_id: id
            })

            // console.log(response.data)
            if (response.data.status != 200) {
                alert(response.data.message)
            }

            else {
                setRequests(response.data.data)
            }
            setLoading(false)



        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        getRequests()
    }, [])
    // Get the parameter from the URL
    return (
        <div>
            {

                loading ? <div>Loading </div> :
                    <>
                        <div>
                            {
                                requests.length === 0 ? <div>No Requests</div> :
                                    <div>

                                        {
                                            requests.map((request: any) => (
                                                <div key={request.booking_time} className='border py-5'>
                                                    <h1>Booking From : {new Date(new Date(0).setUTCSeconds(request.booking_from)).getDate()} /
                                                        {new Date(new Date(0).setUTCSeconds(request.booking_from)).getMonth() + 1} /
                                                        {new Date(new Date(0).setUTCSeconds(request.booking_from)).getFullYear()}
                                                    </h1>
                                                    <h1>Booking Till :
                                                        {new Date(new Date(0).setUTCSeconds(request.booking_to)).getDate()} /
                                                        {new Date(new Date(0).setUTCSeconds(request.booking_to)).getMonth() + 1} /
                                                        {new Date(new Date(0).setUTCSeconds(request.booking_to)).getFullYear()}
                                                    </h1>
                                                    {/* <h1>Booked By : {request.booked_by}</h1> */}
                                                    <button
                                                        className='mx-6'
                                                        onClick={async () => {
                                                            const response = await axios.post("https://homestaykashmir.com:6969/api/approve-request", {
                                                                token: localStorage.getItem("token"),
                                                                ad_id: id,
                                                                booked_by: request.booked_by
                                                            })

                                                            if (response.data.status != 200) {
                                                                alert(response.data.message)
                                                            }
                                                            else {
                                                                alert("Request Approved")
                                                                getRequests()
                                                            }
                                                        }}
                                                    >Accept</button>
                                                    <button
                                                        onClick={async () => {
                                                            const response = await axios.post("https://homestaykashmir.com:6969/api/reject-request", {
                                                                token: localStorage.getItem("token"),
                                                                ad_id: id,
                                                                booking_time: request.booking_time
                                                            })

                                                            if (response.data.status != 200) {
                                                                alert(response.data.message)
                                                            }
                                                            else {
                                                                alert("Request Rejected")
                                                                getRequests()
                                                            }
                                                        }}
                                                    >Reject</button>
                                                </div>
                                            ))
                                        }
                                    </div>
                            }
                        </div>
                    </>

            }
        </div>
    )
}

export default Requests