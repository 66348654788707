import React, { createContext, useState, useContext, ReactNode } from 'react';

interface AuthContextType {
  isLoggedIn: boolean;
  login: () => void;
  logout: () => void;
  toggleAuth: () => void; // New toggle method for dev mode
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // Check if we are in development mode
  const isDevMode = process.env.REACT_APP_DEV_MODE === "true";
  const [isLoggedIn, setIsLoggedIn] = useState(isDevMode); // Set login state based on dev mode

  const login = () => setIsLoggedIn(true);
  const logout = () => setIsLoggedIn(false);
  const toggleAuth = () => setIsLoggedIn((prev) => !prev); // Toggle for dev mode

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, toggleAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
