import React from 'react';

const Terms: React.FC = () => {
  return (
    <>
      <style>
        {`
          .terms-container {
            max-width: 1000px;
            margin: 50px auto;
            padding: 20px;
            background: #ffffff;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            animation: fadeIn 1.5s ease-in-out;
          }
          .terms-title {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 30px;
            text-align: center;
          }
          .terms-section {
            margin-bottom: 20px;
            animation: fadeIn 1.5s ease-in-out;
          }
          .terms-heading {
            font-size: 1.5rem;
            font-weight: bold;
            margin: 20px 0 10px;
            animation: slideUp 1s ease-out;
          }
          .terms-content p, 
          .terms-content li {
            font-size: 1rem;
            line-height: 1.8;
            margin-bottom: 10px;
          }
          .terms-list {
            list-style-type: disc;
            margin-left: 20px;
          }
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes slideUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
      <div className="terms-container">
        <h1 className="terms-title">Terms & Conditions</h1>
        <div className="terms-content">
          <section className="terms-section">
            <h2 className="terms-heading">1. Acceptance of Terms</h2>
            <p>
              By accessing and using HomeStayKashmir's services, you agree to be bound by these Terms and Conditions.
            </p>
          </section>

          <section className="terms-section">
            <h2 className="terms-heading">2. Booking and Cancellation</h2>
            <ul className="terms-list">
              <li>All bookings are subject to availability and confirmation</li>
              <li>Cancellation policies vary by property</li>
              <li>Refunds are processed within 7-14 business days</li>
            </ul>
          </section>

          <section className="terms-section">
            <h2 className="terms-heading">3. User Responsibilities</h2>
            <p>
              Users must provide accurate information and follow local regulations during their stay.
            </p>
          </section>

          {/* Add more sections as needed */}
        </div>
      </div>
    </>
  );
};

export default Terms;
