import React, { useState, useEffect } from 'react';
import { MapPin, Thermometer, Wind, Wifi, Coffee, Car } from 'lucide-react';

interface Room {
  id: number;
  title: string;
  images: string[];
  price: number;
  description: string;
  amenities: string[];
  location: string;
  nearbyPlaces: {
    hospital: string;
    market: string;
    tourist: string[];
  };
  furnished: boolean;
  ac: boolean;
  heater: boolean;
}

const rooms: Room[] = [
  {
    id: 1,
    title: "Luxury Lake View Suite",
    images: [
      "https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=800&h=500",
      "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=800&h=500"
    ],
    price: 5000,
    description: "Experience luxury living with breathtaking views of Dal Lake. This fully furnished suite features modern amenities while maintaining traditional Kashmiri charm...",
    amenities: ["King Size Bed", "Lake View", "Private Balcony", "Room Service", "Mini Kitchen"],
    location: "Dal Lake, Srinagar",
    nearbyPlaces: {
      hospital: "2 km to SKIMS Hospital",
      market: "1 km to Lal Chowk",
      tourist: ["0.5 km to Mughal Gardens", "3 km to Shankaracharya Temple"]
    },
    furnished: true,
    ac: true,
    heater: true
  },
  {
    id: 1,
    title: "Luxury Lake View Suite",
    images: [
      "https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=800&h=500",
      "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=800&h=500"
    ],
    price: 5000,
    description: "Experience luxury living with breathtaking views of Dal Lake. This fully furnished suite features modern amenities while maintaining traditional Kashmiri charm...",
    amenities: ["King Size Bed", "Lake View", "Private Balcony", "Room Service", "Mini Kitchen"],
    location: "Dal Lake, Srinagar",
    nearbyPlaces: {
      hospital: "2 km to SKIMS Hospital",
      market: "1 km to Lal Chowk",
      tourist: ["0.5 km to Mughal Gardens", "3 km to Shankaracharya Temple"]
    },
    furnished: true,
    ac: true,
    heater: true
  },
  {
    id: 1,
    title: "Luxury Lake View Suite",
    images: [
      "https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=800&h=500",
      "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=800&h=500"
    ],
    price: 5000,
    description: "Experience luxury living with breathtaking views of Dal Lake. This fully furnished suite features modern amenities while maintaining traditional Kashmiri charm...",
    amenities: ["King Size Bed", "Lake View", "Private Balcony", "Room Service", "Mini Kitchen"],
    location: "Dal Lake, Srinagar",
    nearbyPlaces: {
      hospital: "2 km to SKIMS Hospital",
      market: "1 km to Lal Chowk",
      tourist: ["0.5 km to Mughal Gardens", "3 km to Shankaracharya Temple"]
    },
    furnished: true,
    ac: true,
    heater: true
  },
  {
    id: 1,
    title: "Luxury Lake View Suite",
    images: [
      "https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=800&h=500",
      "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=800&h=500"
    ],
    price: 5000,
    description: "Experience luxury living with breathtaking views of Dal Lake. This fully furnished suite features modern amenities while maintaining traditional Kashmiri charm...",
    amenities: ["King Size Bed", "Lake View", "Private Balcony", "Room Service", "Mini Kitchen"],
    location: "Dal Lake, Srinagar",
    nearbyPlaces: {
      hospital: "2 km to SKIMS Hospital",
      market: "1 km to Lal Chowk",
      tourist: ["0.5 km to Mughal Gardens", "3 km to Shankaracharya Temple"]
    },
    furnished: true,
    ac: true,
    heater: true
  },
  {
    id: 1,
    title: "Luxury Lake View Suite",
    images: [
      "https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=800&h=500",
      "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=800&h=500"
    ],
    price: 5000,
    description: "Experience luxury living with breathtaking views of Dal Lake. This fully furnished suite features modern amenities while maintaining traditional Kashmiri charm...",
    amenities: ["King Size Bed", "Lake View", "Private Balcony", "Room Service", "Mini Kitchen"],
    location: "Dal Lake, Srinagar",
    nearbyPlaces: {
      hospital: "2 km to SKIMS Hospital",
      market: "1 km to Lal Chowk",
      tourist: ["0.5 km to Mughal Gardens", "3 km to Shankaracharya Temple"]
    },
    furnished: true,
    ac: true,
    heater: true
  },
  {
    id: 1,
    title: "Luxury Lake View Suite",
    images: [
      "https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=800&h=500",
      "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=800&h=500"
    ],
    price: 5000,
    description: "Experience luxury living with breathtaking views of Dal Lake. This fully furnished suite features modern amenities while maintaining traditional Kashmiri charm...",
    amenities: ["King Size Bed", "Lake View", "Private Balcony", "Room Service", "Mini Kitchen"],
    location: "Dal Lake, Srinagar",
    nearbyPlaces: {
      hospital: "2 km to SKIMS Hospital",
      market: "1 km to Lal Chowk",
      tourist: ["0.5 km to Mughal Gardens", "3 km to Shankaracharya Temple"]
    },
    furnished: true,
    ac: true,
    heater: true
  },
  {
    id: 1,
    title: "Luxury Lake View Suite",
    images: [
      "https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=800&h=500",
      "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=800&h=500"
    ],
    price: 5000,
    description: "Experience luxury living with breathtaking views of Dal Lake. This fully furnished suite features modern amenities while maintaining traditional Kashmiri charm...",
    amenities: ["King Size Bed", "Lake View", "Private Balcony", "Room Service", "Mini Kitchen"],
    location: "Dal Lake, Srinagar",
    nearbyPlaces: {
      hospital: "2 km to SKIMS Hospital",
      market: "1 km to Lal Chowk",
      tourist: ["0.5 km to Mughal Gardens", "3 km to Shankaracharya Temple"]
    },
    furnished: true,
    ac: true,
    heater: true
  },
  {
    id: 1,
    title: "Luxury Lake View Suite",
    images: [
      "https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=800&h=500",
      "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=800&h=500"
    ],
    price: 5000,
    description: "Experience luxury living with breathtaking views of Dal Lake. This fully furnished suite features modern amenities while maintaining traditional Kashmiri charm...",
    amenities: ["King Size Bed", "Lake View", "Private Balcony", "Room Service", "Mini Kitchen"],
    location: "Dal Lake, Srinagar",
    nearbyPlaces: {
      hospital: "2 km to SKIMS Hospital",
      market: "1 km to Lal Chowk",
      tourist: ["0.5 km to Mughal Gardens", "3 km to Shankaracharya Temple"]
    },
    furnished: true,
    ac: true,
    heater: true
  },
  {
    id: 1,
    title: "Luxury Lake View Suite",
    images: [
      "https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=800&h=500",
      "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=800&h=500"
    ],
    price: 5000,
    description: "Experience luxury living with breathtaking views of Dal Lake. This fully furnished suite features modern amenities while maintaining traditional Kashmiri charm...",
    amenities: ["King Size Bed", "Lake View", "Private Balcony", "Room Service", "Mini Kitchen"],
    location: "Dal Lake, Srinagar",
    nearbyPlaces: {
      hospital: "2 km to SKIMS Hospital",
      market: "1 km to Lal Chowk",
      tourist: ["0.5 km to Mughal Gardens", "3 km to Shankaracharya Temple"]
    },
    furnished: true,
    ac: true,
    heater: true
  },
  {
    id: 1,
    title: "Luxury Lake View Suite",
    images: [
      "https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=800&h=500",
      "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=800&h=500"
    ],
    price: 5000,
    description: "Experience luxury living with breathtaking views of Dal Lake. This fully furnished suite features modern amenities while maintaining traditional Kashmiri charm...",
    amenities: ["King Size Bed", "Lake View", "Private Balcony", "Room Service", "Mini Kitchen"],
    location: "Dal Lake, Srinagar",
    nearbyPlaces: {
      hospital: "2 km to SKIMS Hospital",
      market: "1 km to Lal Chowk",
      tourist: ["0.5 km to Mughal Gardens", "3 km to Shankaracharya Temple"]
    },
    furnished: true,
    ac: true,
    heater: true
  },
  {
    id: 1,
    title: "Luxury Lake View Suite",
    images: [
      "https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=800&h=500",
      "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=800&h=500"
    ],
    price: 5000,
    description: "Experience luxury living with breathtaking views of Dal Lake. This fully furnished suite features modern amenities while maintaining traditional Kashmiri charm...",
    amenities: ["King Size Bed", "Lake View", "Private Balcony", "Room Service", "Mini Kitchen"],
    location: "Dal Lake, Srinagar",
    nearbyPlaces: {
      hospital: "2 km to SKIMS Hospital",
      market: "1 km to Lal Chowk",
      tourist: ["0.5 km to Mughal Gardens", "3 km to Shankaracharya Temple"]
    },
    furnished: true,
    ac: true,
    heater: true
  },
  
  // Add more rooms here...
];

const RoomCard = ({ room }: { room: Room }) => {
  const [showMore, setShowMore] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prev) => (prev + 1) % room.images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [room.images.length]);

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-duration-300">
      <div className="relative h-64">
        {room.images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`${room.title} - View ${index + 1}`}
            className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${
              index === currentImageIndex ? 'opacity-100' : 'opacity-0'
            }`}
          />
        ))}
      </div>

      <div className="p-6">
        <h3 className="text-2xl font-bold mb-2">{room.title}</h3>
        <div className="flex items-center text-gray-600 mb-4">
          <MapPin className="h-5 w-5 mr-2" />
          <span>{room.location}</span>
        </div>

        <p className="text-gray-600 mb-4">
          {showMore ? room.description : `${room.description.slice(0, 150)}...`}
          <button
            onClick={() => setShowMore(!showMore)}
            className="text-indigo-600 hover:text-indigo-800 ml-2"
          >
            {showMore ? 'Show Less' : 'Show More'}
          </button>
        </p>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="flex items-center">
            <Thermometer className="h-5 w-5 mr-2 text-gray-500" />
            <span>{room.ac ? 'AC Available' : 'Non-AC'}</span>
          </div>
          <div className="flex items-center">
            <Wind className="h-5 w-5 mr-2 text-gray-500" />
            <span>{room.heater ? 'Heater Available' : 'No Heater'}</span>
          </div>
          <div className="flex items-center">
            <Wifi className="h-5 w-5 mr-2 text-gray-500" />
            <span>Free WiFi</span>
          </div>
          <div className="flex items-center">
            <Coffee className="h-5 w-5 mr-2 text-gray-500" />
            <span>Breakfast Included</span>
          </div>
        </div>

        <div className="border-t pt-4">
          <h4 className="font-semibold mb-2">Nearby Places:</h4>
          <div className="space-y-1 text-sm text-gray-600">
            <div className="flex items-center">
              <Car className="h-4 w-4 mr-2" />
              <span>{room.nearbyPlaces.hospital}</span>
            </div>
            <div className="flex items-center">
              <MapPin className="h-4 w-4 mr-2" />
              <span>{room.nearbyPlaces.market}</span>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-between items-center">
          <div className="text-2xl font-bold text-indigo-600">
            ₹{room.price}/night
          </div>
          <a
            href={`/booking/${room.id}`}
            className="bg-indigo-600 text-white px-6 py-2 rounded-md hover:bg-indigo-700 transition-colors"
          >
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
};

const Rooms = () => {
  const [loading, setLoading] = useState(false);
  const [displayedRooms, setDisplayedRooms] = useState(8);

  const loadMore = () => {
    setLoading(true);
    setTimeout(() => {
      setDisplayedRooms(prev => Math.min(prev + 8, rooms.length));
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Available Rooms</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {rooms.slice(0, displayedRooms).map(room => (
          <RoomCard key={room.id} room={room} />
        ))}
      </div>
      {displayedRooms < rooms.length && (
        <div className="text-center mt-8">
          <button
            onClick={loadMore}
            className="bg-indigo-600 text-white px-8 py-3 rounded-md hover:bg-indigo-700 transition-colors"
            disabled={loading}
          >
            {loading ? (
              <div className="flex items-center">
                <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Loading...
              </div>
            ) : (
              'Load More'
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default Rooms;