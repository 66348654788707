import React from 'react';

const Payment: React.FC = () => {
  const handleBookNow = () => {
    // Redirect to payment gateway or handle booking logic
    alert("Redirecting to payment gateway...");
  };

  return (
    <>
      <style>
        {`
          .payment-container {
            max-width: 800px;
            margin: 50px auto;
            padding: 20px;
            background: #ffffff;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            animation: fadeIn 1.5s ease-in-out;
          }
          .payment-title {
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: center;
          }
          .payment-form {
            display: flex;
            flex-direction: column;
            gap: 15px;
          }
          .payment-form label {
            font-weight: 600;
          }
          .payment-form input,
          .payment-form textarea {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            font-size: 1rem;
          }
          .payment-form textarea {
            resize: none;
          }
          .payment-button {
            margin-top: 20px;
            padding: 12px;
            background-color: #4caf50;
            color: white;
            border: none;
            border-radius: 5px;
            font-size: 1rem;
            font-weight: bold;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }
          .payment-button:hover {
            background-color: #45a049;
          }
          .payment-gallery {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            margin-top: 20px;
          }
          .payment-gallery img {
            width: 100%;
            height: auto;
            border-radius: 5px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}
      </style>
      <div className="payment-container">
        <h1 className="payment-title">Book Your HomeStay</h1>
        <form className="payment-form">
          <div>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" placeholder="Enter your full name" required />
          </div>
          <div>
            <label htmlFor="idProof">Passport or Aadhaar</label>
            <input type="text" id="idProof" placeholder="Enter your ID number" required />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input type="email" id="email" placeholder="Enter your email address" required />
          </div>
          <div>
            <label htmlFor="otp">OTP</label>
            <input type="text" id="otp" placeholder="Enter the OTP" required />
          </div>
          <div>
            <label htmlFor="description">Additional Description</label>
            <textarea
              id="description"
              rows={4}
              placeholder="Provide any additional information about your stay"
            ></textarea>
          </div>
          <div className="payment-gallery">
            <img src="https://via.placeholder.com/150" alt="HomeStay 1" />
            <img src="https://via.placeholder.com/150" alt="HomeStay 2" />
            <img src="https://via.placeholder.com/150" alt="HomeStay 3" />
            <img src="https://via.placeholder.com/150" alt="HomeStay 4" />
          </div>
          <button type="button" className="payment-button" onClick={handleBookNow}>
            Book HomeStay
          </button>
        </form>
      </div>
    </>
  );
};

export default Payment;
