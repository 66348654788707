import React from "react";
import axios from "axios";
import { useAuth } from "../components/context/AuthContext";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const Login = () => {
  const { login } = useAuth(); // Auth context for managing login state
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const token = localStorage.getItem("token");

  // Handle successful Google Login
  const handleLoginSuccess = async (response: any) => {
    try {
      const token = response.credential;
      const userObject = JSON.parse(atob(token.split(".")[1])); // Decode JWT to get user info
      const { email, name } = userObject;

      const data = await axios.post("https://homestaykashmir.com:6969/api/google-login", { email });

      if (data.data.status === 200) {
        localStorage.setItem("token", data.data.token);
        login(); // Update AuthContext state
        toast.success("Logged in successfully via Google");
        navigate("/");
      } else {
        toast.error(data.data.message);
      }
    } catch (error) {
      console.error("Google Login Error:", error);
      toast.error("Google Login failed!");
    }
  };

  // Check token validity on page load
  const checkToken = async () => {
    if (token) {
      const data = await axios.post("https://homestaykashmir.com:6969/api/verify-token", { token });
      if (data.data.status === 200) {
        login(); // Update AuthContext state
        navigate("/");
      }
    }
  };

  React.useEffect(() => {
    checkToken();
  }, []);

  // Handle form login
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = await axios.post("https://homestaykashmir.com:6969/api/login", { email, password });

      if (data.data.status === 200) {
        toast.success("Logged in successfully");
        localStorage.setItem("token", data.data.token);
        login(); // Update AuthContext state
        navigate("/");
      } else {
        toast.error(data.data.message);
      }
    } catch (error) {
      console.error("Login Error:", error);
      toast.error("Login failed!");
    }
  };

  return (
    <>
      <Toaster
        toastOptions={{
          duration: 2000,
        }}
      />
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>

            <GoogleOAuthProvider clientId="446750503828-uj0m2t1ubnqnsd48483sv1brb624oegj.apps.googleusercontent.com">
              <GoogleLogin onSuccess={handleLoginSuccess} onError={() => toast.error("Google Login failed!")} />
            </GoogleOAuthProvider>

            <div className="text-sm text-center">
              <p>
                Don't have an account?{" "}
                <a href="/register" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Sign up
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
