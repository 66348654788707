import React from 'react';

const Privacy: React.FC = () => {
  return (
    <>
      <style>
        {`
          .privacy-container {
            max-width: 1000px;
            margin: 50px auto;
            padding: 20px;
            background: #ffffff;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            animation: fadeIn 1.5s ease-in-out;
          }
          .privacy-title {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 30px;
            text-align: center;
          }
          .privacy-section {
            margin-bottom: 20px;
            animation: fadeIn 1.5s ease-in-out;
          }
          .privacy-heading {
            font-size: 1.5rem;
            font-weight: bold;
            margin: 20px 0 10px;
            animation: slideUp 1s ease-out;
          }
          .privacy-content p, 
          .privacy-content li {
            font-size: 1rem;
            line-height: 1.8;
            margin-bottom: 10px;
          }
          .privacy-list {
            list-style-type: disc;
            margin-left: 20px;
          }
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes slideUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
      <div className="privacy-container">
        <h1 className="privacy-title">Privacy Policy</h1>
        <div className="privacy-content">
          <section className="privacy-section">
            <h2 className="privacy-heading">Information We Collect</h2>
            <p>
              We collect information that you provide directly to us, including but not limited to:
            </p>
            <ul className="privacy-list">
              <li>Name and contact information</li>
              <li>Payment details</li>
              <li>Booking history</li>
              <li>Communication preferences</li>
            </ul>
          </section>

          <section className="privacy-section">
            <h2 className="privacy-heading">How We Use Your Information</h2>
            <p>
              Your information is used to provide and improve our services, process transactions, and communicate with you about your bookings.
            </p>
          </section>

          {/* Add more sections as needed */}
        </div>
      </div>
    </>
  );
};

export default Privacy;
