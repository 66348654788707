import React from 'react';

const About: React.FC = () => {
  return (
    <>
      <style>
        {`
          .about-container {
            max-width: 1000px;
            margin: 50px auto;
            padding: 20px;
            background: #ffffff;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            animation: fadeIn 1.5s ease-in-out;
          }
          .about-title {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 30px;
            text-align: center;
          }
          .about-content p {
            font-size: 1rem;
            line-height: 1.8;
            margin-bottom: 20px;
          }
          .about-heading {
            font-size: 1.5rem;
            font-weight: bold;
            margin: 20px 0 10px;
            animation: slideUp 1s ease-out;
          }
          .about-list {
            list-style-type: disc;
            margin-left: 20px;
            animation: fadeIn 1.5s ease-in-out;
          }
          .about-list li {
            margin-bottom: 10px;
          }
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes slideUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
      <div className="about-container">
        <h1 className="about-title">About HomeStayKashmir</h1>
        <div className="about-content">
          <p>
            <strong>HomeStayKashmir</strong> is Kashmir's premier homestay platform, connecting travelers with authentic local experiences in the paradise on Earth. We pride ourselves on delivering unmatched hospitality while fostering sustainable tourism in the region.
          </p>

          <h2 className="about-heading">Our Mission</h2>
          <p>
            Our mission is to provide travelers with genuine Kashmiri hospitality while empowering local homeowners to share their spaces and culture with the world.
          </p>

          <h2 className="about-heading">Our Story</h2>
          <p>
            Founded in 2024, HomeStayKashmir emerged from a vision to showcase Kashmir's legendary hospitality to the world while creating sustainable tourism opportunities for local communities.
          </p>

          <h2 className="about-heading">Why Choose Us?</h2>
          <ul className="about-list">
            <li>Curated selection of authentic homestays</li>
            <li>Direct connection with local hosts</li>
            <li>Secure booking and payment system</li>
            <li>24/7 customer support</li>
            <li>Local expertise and guidance</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default About;
