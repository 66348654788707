import React from 'react'
import axios from 'axios'
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom'

const MyAds = () => {
    const navigate = useNavigate();
    const [ads, setAds] = React.useState([]);
    async function getMyAds() {
        const token = localStorage.getItem("token");
        const response = await axios.post("https://homestaykashmir.com:6969/api/ads-by-token", {
            token
        });
        console.log(response.data);
        setAds(response.data.ads);
    }

    React.useEffect(() => {
        getMyAds();
    }, []);
    return (
        <div>
            <Navbar />
            {
                ads.map((ad: any) => {
                    return (
                        <div key={ad.id} className="border p-2 m-2">

                            <h1>Title : {ad.title}</h1>
                            <h1>Description : {ad.description}</h1>
                            <h1>House City : {ad.house_city}</h1>
                            <h1>House State : {ad.house_state}</h1>
                            <h1>Price Per Room : {ad.price_per_room}</h1>
                            <h1>Room Cooling : {ad.room_cooling === 1 ? "Available" : "Not Available"}</h1>
                            <h1>Room Furnished : {ad.furnished}</h1>
                            <h1>Hot Cold Water : {ad.hot_cold_water === 1 ? "Available" : "Not Available"}</h1>
                            <h1>Food : {ad.food === 1 ? "Available" : "Not Available"}</h1>
                            <h1>Taxi : {ad.taxi_service === 1 ? "Available" : "Not Available"}</h1>
                            <h1>Doctor on Call : {ad.doctor_on_call === 1 ? "Available" : "Not Available"}</h1>

                            <button onClick={
                                // go to /my-ads/requests/:id
                                () => { navigate(`/my-ads/requests/${ad.id}`) }

                            }>Go to Requests</button>
                        </div>
                    )
                })
            }
            {/*  see approval requests  */}

        </div>
    )
}

export default MyAds