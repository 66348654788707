import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
    const navigate = useNavigate()
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (username.length <= 0 || password.length <= 0) {
            alert("Please fill in all fields")
            return
        }

        const response = await axios.post("https://homestaykashmir.com:6969/api/admin-login", {
            email: username,
            password: password
        })

        if (response.data.status === 200) {
            alert("Login successful")
            localStorage.setItem("admin-token", response.data.token)
            navigate("/admin-dashboard")
        } else {
            alert("Login failed")
        }

    }
    return (
        <>
            <div>
                <div className="flex justify-center">
                    <div className="bg-white p-10 rounded-lg shadow-2xl w-1/4">
                        <h1 className="text-2xl font-bold">Admin Login</h1>
                        <div className="mt-10">
                            <input value={username} onChange={(e) => setUsername(e.target.value)} type="text" placeholder="Username" className="border-b-2 border-gray-400 w-full p-2" />
                        </div>
                        <div className="mt-5">
                            <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" className="border-b-2 border-gray-400 w-full p-2" />
                        </div>
                        <div className="mt-10">
                            <button className="bg-blue-500 text-white p-2 rounded-lg w-full" onClick={handleSubmit}>Login</button>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default AdminLogin