import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './components/context/AuthContext'; // Import AuthProvider

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId="446750503828-uj0m2t1ubnqnsd48483sv1brb624oegj.apps.googleusercontent.com
">
    <React.StrictMode>
      <AuthProvider> {/* Wrap App with AuthProvider */}
        <App />
      </AuthProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

reportWebVitals();
