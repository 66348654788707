import React from 'react';

const homestays = [
  {
    image: 'https://images.unsplash.com/photo-1571003123894-1f0594d2b5d9?fit=crop&w=600&h=400',
    title: 'Lakeside Haven',
    location: 'Dal Lake, Srinagar',
    price: '₹2,500/night',
    rating: 4.8,
    description: 'Experience luxury living by the serene Dal Lake with panoramic mountain views.',
    amenities: ['Lake View', 'Garden', 'Free WiFi', 'Traditional Breakfast'],
  },
  {
    image: 'https://images.unsplash.com/photo-1566073771259-6a8506099945?fit=crop&w=600&h=400',
    title: 'Mountain Retreat',
    location: 'Gulmarg',
    price: '₹3,000/night',
    rating: 4.9,
    description: 'Cozy mountain cottage with stunning views of snow-capped peaks.',
    amenities: ['Mountain View', 'Fireplace', 'Skiing Equipment', 'Room Service'],
  },
  {
    image: 'https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?fit=crop&w=600&h=400',
    title: 'Valley View Villa',
    location: 'Pahalgam',
    price: '₹2,800/night',
    rating: 4.7,
    description: 'Luxurious villa overlooking the beautiful Lidder Valley.',
    amenities: ['Valley View', 'Private Balcony', 'Heated Rooms', 'Restaurant'],
  },
];

const FeaturedHomestays = () => {
  return (
    <section className="container mx-auto px-4 py-16">
      <h2 className="text-3xl font-bold text-center mb-12">Featured Homestays</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {homestays.map((homestay, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition-transform duration-300">
            <img
              src={homestay.image}
              alt={homestay.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2">{homestay.title}</h3>
              <p className="text-gray-600 mb-2">{homestay.location}</p>
              <p className="text-gray-700 text-sm mb-4">{homestay.description}</p>
              <div className="mb-4">
                <h4 className="text-sm font-semibold mb-2">Amenities:</h4>
                <div className="flex flex-wrap gap-2">
                  {homestay.amenities.map((amenity, i) => (
                    <span key={i} className="text-xs bg-gray-100 px-2 py-1 rounded">
                      {amenity}
                    </span>
                  ))}
                </div>
              </div>
              <div className="flex justify-between items-center pt-4 border-t">
                <span className="text-lg font-bold text-indigo-600">
                  {homestay.price}
                </span>
                <div className="flex items-center">
                  <span className="text-yellow-400">★</span>
                  <span className="ml-1">{homestay.rating}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturedHomestays;