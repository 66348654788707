import React, { useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

const MyBookings = () => {
  const navigate = useNavigate();
  // let bookings:any = []
  const [bookings, setBookings] = React.useState([])
  async function getBookings() {
    const data = await axios.post('https://homestaykashmir.com:6969/api/booking-by-token', {
      token: localStorage.getItem("token")
    })
    // bookings = data.data.bookings
    console.log(data.data.bookings)
    setBookings(data.data.bookings)
    console.log(bookings)
  }
  useEffect(() => {
    getBookings()
  }, [])




  return (
    <>
      <Navbar />
      {
        bookings.map((booking: any) => {
          return (
            // <div key={booking.booking.ad_id} className=''>
            //   <h1>{booking.ad.title}</h1>
            //   <h1>{booking.ad.description}</h1>
            // </div>
            // do proper styling
            <div key={booking.booking.ad_id} className="
          border-2
          border-gray-300
          rounded-lg
          p-4
          m-4

          ">
              <h1>Title : {booking.ad.title}</h1>
              <h1>Description : {booking.ad.description}</h1>
              <h1>House City : {booking.ad.house_city}</h1>
              <h1>House State : {booking.ad.house_state}</h1>
              <h1>Price Per Room : {booking.ad.price_per_room}</h1>
              <h1>Room Cooling : {booking.ad.room_cooling === 1 ? "Available" : "Not Available"}</h1>
              <h1>Room Furnished : {booking.ad.furnished}</h1>
              <h1>Hot Cold Water : {booking.ad.hot_cold_water === 1 ? "Available" : "Not Available"}</h1>
              <h1>Food : {booking.ad.food === 1 ? "Available" : "Not Available"}</h1>
              <h1>Taxi : {booking.ad.taxi_service === 1 ? "Available" : "Not Available"}</h1>
              <h1>Doctor on Call : {booking.ad.doctor_on_call === 1 ? "Available" : "Not Available"}</h1>


              <button onClick={() => navigate(`/ads/${booking.booking.ad_id}`)}>Go to Ad</button>

            </div>
          )
        })
      }
    </>
  )
}

export default MyBookings