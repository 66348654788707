import React from 'react';
import { Menu, X, Home, PlusCircle, User, Receipt, LogOut, LogIn, UserPlus, Bed } from 'lucide-react';
import { Link } from 'react-router-dom';
import Logo from '../components/Logo';

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoggedIn] = React.useState(true); // TODO: Replace with actual auth state

  const menuItems = isLoggedIn
    ? [
        { icon: Home, label: 'Home', href: '/' },
        { icon: Bed, label: 'Rooms', href: '/rooms' },
        { icon: PlusCircle, label: 'Create Ad', href: '/create-ad' },
        { icon: User, label: 'Profile', href: '/profile' },
        { icon: Receipt, label: 'My Transactions', href: '/transactions' },
        { icon: LogOut, label: 'Logout', href: '/logout' },
      ]
    : [
        { icon: Home, label: 'Home', href: '/' },
        { icon: Bed, label: 'Rooms', href: '/rooms' },
        { icon: LogIn, label: 'Sign In', href: '/signin' },
        { icon: UserPlus, label: 'Sign Up', href: '/signup' },
      ];

  return (
    <header className="fixed top-0 w-full bg-white shadow-md z-50">
      <nav className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center space-x-2">
            <Logo />
          </Link>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            {menuItems.map(({ icon: Icon, label, href }) => (
              <Link
                key={label}
                to={href}
                className="flex items-center space-x-1 text-gray-600 hover:text-indigo-600 transition-colors"
              >
                <Icon className="h-4 w-4" />
                <span>{label}</span>
              </Link>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-gray-600 hover:text-gray-900"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden mt-4 pb-4">
            {menuItems.map(({ icon: Icon, label, href }) => (
              <Link
                key={label}
                to={href}
                className="flex items-center space-x-2 px-4 py-3 text-gray-600 hover:bg-gray-50"
                onClick={() => setIsOpen(false)}
              >
                <Icon className="h-5 w-5" />
                <span>{label}</span>
              </Link>
            ))}
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;