import axios from 'axios'
import React from 'react'

const AdminVerifyOtp = () => {

    const [otp, setOtp] = React.useState("")
    const [verified, setVerified] = React.useState(false)

    const [bookingData, setBookingData] = React.useState({
        ad_id: '',
        booked_by: '',
        posted_by: '',
        booking_time: '',
        approved: 0,
        booking_from: '',
        booking_to: '',
        rating: null,
        otp: '',
        checkedIn: 0
    })
    const [loading, setLoading] = React.useState(false)

    // check if otp valid
    async function VerifyBookingOtp() {
        setLoading(true)
        const d = await axios.post("https://homestaykashmir.com:6969/api/verify-booking-otp", {
            otp: otp,
            token: localStorage.getItem("admin-token")
        })

        if (d.data.status != 200) {
            alert("OTP not Verified")
        } else {
            setBookingData(d.data.data)
            setVerified(true)
            setLoading(false)
            console.log(bookingData)
        }
    }
    return (
        <>
            <h1>Admin Verify Otp</h1>
            {/* input for otp */}
            <input type="text" placeholder="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
            {/* verify otp button */}
            <button onClick={VerifyBookingOtp}>Verify OTP</button>
            {/* if otp verified show booking data */}
            {loading ? <h1>Loading...</h1> : <>
                {
                    !verified ? <></> : <>

                        <h1 className='text-green-500'> VERIFIED BOOKING</h1>
                        <h1>Booking Data</h1>
                        <p>Approved: {bookingData.approved === 1 ? "YES" : "NO"}</p>
                        <p>Booking From : {new Date(new Date(0).setUTCSeconds(Number(bookingData.booking_from))).getDate()} /
                            {new Date(new Date(0).setUTCSeconds(Number(bookingData.booking_from))).getMonth() + 1} /
                            {new Date(new Date(0).setUTCSeconds(Number(bookingData.booking_from))).getFullYear()}</p>
                        <p>Booking To : {new Date(new Date(0).setUTCSeconds(Number(bookingData.booking_to))).getDate()} /
                            {new Date(new Date(0).setUTCSeconds(Number(bookingData.booking_to))).getMonth() + 1} /
                            {new Date(new Date(0).setUTCSeconds(Number(bookingData.booking_to))).getFullYear()}</p>

                        <p>OTP: {bookingData.otp}</p>
                    </>
                }

            </>}


        </>
    )
}

export default AdminVerifyOtp