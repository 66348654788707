import React from 'react';

const Hero = () => {
  return (
    <section className="relative h-[600px] flex items-center justify-center">
      {/* Main background image */}
      <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1566837497312-7be4a47b6ec7?auto=format&fit=crop&w=2000&q=80")',
        }}
      ></div>

      {/* Gradient overlay for better text readability */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-950/90 via-blue-900/80 to-gray-900/90"></div>

      {/* Content container with decorative background */}
      <div className="relative text-center text-white px-4 max-w-4xl mx-auto">
        <div className="relative">
          {/* Decorative background image behind text */}
          <div 
            className="absolute inset-0 blur-sm opacity-20 bg-cover bg-center rounded-xl"
            style={{
              backgroundImage: 'url("https://images.unsplash.com/photo-1593096725694-c40fc145f42c?auto=format&fit=crop&w=1200&q=80")',
              transform: 'scale(1.1)',
            }}
          ></div>
          
          {/* Content */}
          <div className="relative py-12 px-6 rounded-xl backdrop-blur-sm bg-gradient-to-r from-blue-950/30 to-gray-900/30">
            <h1 className="text-4xl md:text-6xl font-bold mb-6 [text-shadow:_2px_2px_4px_rgb(0_0_0_/_50%)]">
              Experience Kashmir Like Never Before
            </h1>
            <p className="text-xl md:text-2xl mb-8 [text-shadow:_1px_1px_2px_rgb(0_0_0_/_50%)]">
              Find your perfect homestay in the paradise on Earth
            </p>
            <button className="bg-gradient-to-r from-indigo-600 to-blue-700 text-white px-8 py-3 rounded-md font-semibold hover:from-indigo-700 hover:to-blue-800 transition-all duration-300 shadow-lg">
              Explore Homestays
            </button>
          </div>
        </div>
      </div>

      {/* Decorative overlay gradient at bottom */}
      <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-white to-transparent"></div>
    </section>
  );
};

export default Hero;