import React from "react";
import { useAuth } from "../components/context/AuthContext";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token"); // Remove token
    logout(); // Update AuthContext
    navigate("/login"); // Redirect to Login page
  };

  return (
    <button onClick={handleLogout} className="bg-red-500 px-4 py-2 rounded text-white hover:bg-red-600">
      Log Out
    </button>
  );
};

export default LogoutButton;
